import React from "react"
import styled from "styled-components"

// import BackLink from "../components/BackLink"
import Layout from "./Layout"
import { Container, Row, Col } from "../components/Grid"

import { media } from "../styles"

const TextPageWrapper = styled.div`
  padding: 35px 0;

  @media ${media.sm} {
    padding: 70px 0;
  }

  @media ${media.md} {
    padding: 95px 0;
  }
`

const TextPageContainer = styled.div`
  h1,
  .h1 {
    color: ${({ theme }) => theme.brandSecondaryDark};
  }
`

const TextPage = (props) => {
  return (
    <Layout pageId={props.pageId}>
      <TextPageWrapper>
        <Container>
          {/* <BackLink /> */}
          <Row>
            <Col sm={10} md={9}>
              <TextPageContainer className="text-small">
                {props.children}
              </TextPageContainer>
            </Col>
          </Row>
        </Container>
      </TextPageWrapper>
    </Layout>
  )
}

export default TextPage
