import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"

import TextPage from "../layouts/TextPage"
import SEO from "../components/Seo"
import Heading from "../components/Heading"

const NotFoundPage = ({ intl }) => (
  <TextPage pageId="404">
    <SEO title={`404: ${intl.formatMessage({ id: "title" })}`} />
    <Heading type="h1">
      <FormattedMessage id="notfound.header" />
    </Heading>
    <p>
      <FormattedMessage id="notfound.description" />
    </p>
  </TextPage>
)

export default injectIntl(NotFoundPage)
